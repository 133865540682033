<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__title">
        <span>Criação de botão de ação</span>
      </div>
    </div>

    <v-form ref="form">
      <div class="modal__body">
        <z-autocomplete
          v-model="buttonInfo.type"
          label="Selecione uma tipo"
          :items="types"
          itemText="name"
          placeholder="Pesquisar"
          type="text"
          :hideDetails="true"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          @change="updateObject"
        />

        <z-input
          v-model="buttonInfo.text"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :hideDetails="true"
          placeholder="Título do botão"
          type="text"
        />

        <!-- CASO O TIPO SEJA URL -->
        <z-input
          v-if="buttonInfo.type.toLocaleLowerCase() == 'url'"
          v-model="buttonInfo.url"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :hideDetails="true"
          placeholder="URL do botão"
          type="text"
        />

        <!-- CASO O TIPO SEJA PHONE_NUMBER -->
        <z-input
          v-else-if="buttonInfo.type.toLocaleLowerCase() == 'phone_number'"
          v-model="buttonInfo.phoneNumber"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :hideDetails="true"
          placeholder="Número do telefone"
          type="text"
        />

        <!-- DEFAULT -->
        <z-input
          v-else
          v-model="buttonInfo.default"
          :rules="[(v) => !!v || 'Campo obrigatório']"
          :hideDetails="true"
          placeholder="Informação do botão"
          type="text"
        />
      </div>
    </v-form>

    <div class="modal__footer d-flex justify-end">
      <z-btn text="Cancelar" color="primary" class="mr-2" @click="$emit('close')" />
      <z-btn :text="buttonText" primary color="primary" @click="createOrEditNewButton" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentButtonProp: { type: Object, default: null },
  },
  computed: {
    buttonText() {
      return this.currentButtonProp ? "Atualizar" : "Criar";
    },
  },

  created() {
    if (this.currentButtonProp) {
      this.buttonInfo = { ...this.currentButtonProp };
    }
  },
  data() {
    return {
      types: ["PHONE_NUMBER", "URL", "QUICK_REPLY", "COPY_CODE"],
      buttonInfo: { type: "" },
    };
  },
  methods: {
    updateObject() {
      //  apagar as chaves url e phone number dinamicamente
      // dependdendo to tipo de type que for selecionado
      this.buttonInfo.type == "PHONE_NUMBER"
        ? delete this.buttonInfo.url
        : this.buttonInfo.type == "URL"
        ? delete this.buttonInfo.phoneNumber
        : delete this.buttonInfo.phoneNumber,
        delete this.buttonInfo.url;
    },
    createOrEditNewButton() {
      if (this.$refs.form.validate()) {
        if (!this.currentButtonProp) this.$emit("close", this.buttonInfo);
        else {
          this.$emit("setNewButtonValue", {
            ...this.buttonInfo,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  &__header {
    &__title {
      span {
        font-weight: 600;
        text-transform: uppercase;
        line-height: 15px;
        font-size: 1rem;
      }
    }
  }

  &__body {
    margin: $z-s-1 0;
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
  }
}
</style>
